import { z } from 'zod';
import { contract } from '../init-contract';
import { InspectionFileSchema, InspectionSchema } from './inspection.schema';
import { ERROR } from '../@types';

export const inspectionContract = contract.router({
  create: {
    method: 'POST',
    path: '/inspection/create',
    responses: {
      200: contract.type<InspectionSchema[]>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      city: z.string(),
      state_id: z.number(),
      responsible_id: z.number(),
      requester_id: z.number(),
      authority_id: z.number().optional(),
      ship_type_id: z.number(),
      ship_imo: z.number().refine(Number),
      ship_cradle: z.string(),
      ship_flag: z.string(),
      ship_name: z.string(),
      driverIds: z.array(z.number()),
      dogIds: z.array(z.number()),
      ship_terminal: z.string(),
      date: z.date(),
    }),
  },
  list: {
    method: 'GET',
    path: '/inspection/all',
    responses: {
      200: contract.type<InspectionSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },
  requester: {
    method: 'GET',
    path: '/inspection/requester/all',
    responses: {
      200: contract.type<InspectionSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },
  authoritie: {
    method: 'GET',
    path: '/inspection/authority/all',
    responses: {
      200: contract.type<InspectionSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  update: {
    method: 'PUT',
    path: '/inspection/:inspectionId',
    responses: {
      200: contract.type<InspectionSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      city: z.string(),
      state_id: z.number(),
      responsible_id: z.number(),
      requester_id: z.number(),
      ship_type_id: z.number(),
      ship_imo: z.number().refine(Number),
      ship_cradle: z.string(),
      ship_flag: z.string(),
      ship_name: z.string(),
      driverIds: z.array(z.number()),
      dogIds: z.array(z.number()),
      ship_terminal: z.string(),
      date: z.date(),
    }),
    pathParams: z.object({
      inspectionId: z.number(),
    }),
  },
  show: {
    method: 'GET',
    path: '/inspection/:inspectionId',
    responses: {
      200: contract.type<InspectionSchema>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      inspectionId: z.number(),
    }),
  },
  delete: {
    method: 'DELETE',
    path: '/inspection/:inspectionId',
    responses: {
      200: contract.type<InspectionSchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      inspectionId: z.number(),
    }),
  },
  files: {
    method: 'GET',
    path: '/inspection/:inspectionId/files',
    responses: {
      200: contract.type<InspectionFileSchema[]>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      inspectionId: z.number(),
    }),
  },

  report: {
    method: 'PUT',
    path: '/inspection/:inspectionId/report',
    responses: {
      200: contract.type<InspectionSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      shipName: z.string(),
      terminalName: z.string(),
      agulheirosEstavam: z.enum(['true', 'false']),
      tinhaCasariaDeGuindaste: z.enum(['true', 'false']),
      // inspectionId: z.string().optional(),
      infoAboutCompanions: z.string(),
      infoAboutOnboardShip: z.string(),
      infoAboutExternalInspection: z.string(),
      infoAboutInternalInspection: z.string(),
      infoAboutMachineRoomInspection: z.string(),
      infoAboutDogs: z.string(),
      additionalInfo: z.string().optional(),
    }),
    pathParams: z.object({
      inspectionId: z.number(),
    }),
  },
});
