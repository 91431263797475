// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const fr = {
  logOut: 'Sair',
  no_inspection: 'Não possue nenhuma inspeção ainda.',
  signIn: {
    welcome: 'Olá, bem-vindo de volta',
    loginDBK9: 'Faça login na DBK9',
    inserData: 'Insira seus dados abaixo.',
    email: 'Endereço de email',
    password: 'Senha',
    forgetPassword: 'Esqueceu sua senha?',
    login: 'Login',
  },
  inspection: {
    title: 'Inspeções',
    heading: 'Lista de inspeções',
    list: 'Lista',
    newInspection: ' Nova inspeção',
    started: 'Iniciada',
    pending: 'Pendente',
    finished: 'Terminado',
  },
  status: {
    title: 'Inspeção',
    heading: 'Informação da Inspeção',
    inspection: 'Inspeção',
    hour: 'Horário',
    atTerminal: 'CHEGADA AO TERMINAL PORTUÁRIO',
    onBoard: 'SUBIDA A BORDO',
    outBoard: 'SAÍDA A BORDO',
    internalInspectionStartedAt: 'INICIO INSPEÇÃO INTERNA',
    internalInspectionFinishedAt: 'FINAL INSPEÇÃO INTERNA',
    externalInspectionStartedAt: 'INICIO INSPECÃO EXTERNA',
    externalInspectionFinishedAt: 'FINAL INSPEÇÃO EXTERNA',
    onMachineRoomStartedAt: 'INICIO INSPEÇÃO SALA DE MÁQUINAS',
    onMachineRoomFinishedAt: 'FINAL DE INSPEÇÃO SALA DE MÁQUINAS',
    inspectionEnd: 'FIM DE INSPEÇÃO',
    status: 'Status',
    info1: 'NOSSA EQUIPE ESTÁ REALIZANDO INSPEÇÃO, EM BREVE TEREMOS MAIS INFORMAÇÕES',
    info2:
      'SE A EQUIPE TIVER ACESSO À INTERNET NO FINAL DO PERÍODO DE INSPEÇÃO VOCÊ TERÁ ACESSO À MÍDIA NO LOCAL INSPECIONADO',

    midia: 'Mídia',
    finalReport: 'Relatório final',
  },
  midia: {
    title: 'Inspeção',
    heading: 'Arquivos da inspeção',
    list: 'Lista',
    files: 'Arquivos',
    inspection: 'Inspeção',
    report: 'relatório',
    noFile: 'Nenhum ficheiro foi carregado ainda',
    ship_frontal_image: 'Subir a bordo',
    ship_back_image: 'Descer a bordo',
    internal_inspection_image: 'Inspecção interna',
    external_inspection_image: 'Inspecção externa',
    machine_room_image: 'Sala das máquinas',
    not_inspectioned_local_image: 'Local não inspeccionado',
    crew_list_image: 'Lista de tripulantes',
    imediate_report_image: 'Relatório imediato',
    final_front_ship_image: 'Final frente ao navio',
    drog_found_image: 'Entorpecentes encontrados',
    midia_image_deck_tag: 'Deck número {{deckNumber}}',
    photo_front_terminal: 'Chegada ao terminal portuário',
  },
};

export default fr;
