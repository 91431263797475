import { z } from 'zod';

export const CompanioSchema = z.object({
  id: z.number(),
  bucket: z.string(),
  name: z.string(),
  role: z.string(),
  inspectionId: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type CompanioSchema = z.infer<typeof CompanioSchema>;

export const ImageSchema = z.object({
  id: z.number(),
  inspectionId: z.number(),
  mimeType: z.string(),
  bucket: z.string(),
  objectId: z.string(),
  uploadedBy: z.number(),
  tag: z.object({
    id: z.number(),
    tag: z.string(),
    description: z.string(),
  }),
  pickedAt: z.date(),
  geolocation: z.string(),
});

export type ImageSchema = z.infer<typeof ImageSchema>;

export const ReportSchema = z.object({
  id: z.number(),
  inspectionId: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
  hasDrugs: z.boolean(),
  localWhereDrugsWereFounded: z.string(),
  authorityName: z.string(),
  authorityRole: z.string(),
  shipName: z.string(),
  terminalName: z.string(),
  infoAboutOnboardShip: z.string(),
  infoAboutInternalInspection: z.string(),
  infoAboutMachineRoomInspection: z.string(),
  infoAboutExternalInspection: z.string(),
  infoAboutDogs: z.string(),
  infoAboutCompanions: z.string(),
  agulheirosEstavam: z.boolean(),
  tinhaCasariaDeGuindaste: z.boolean(),
  infoAboutSituacoesAtipicas: z.boolean(),
  additionalInfo: z.string(),
});

export type ReportSchema = z.infer<typeof ReportSchema>;

export const InspectionSchema = z.object({
  id: z.number(),
  name: z.string(),
  city: z.string(),
  createdBy: z.number(),
  responsibleId: z.number(),
  statusId: z.number(),
  shipTypeId: z.number(),
  shipFlag: z.string(),
  shipName: z.string(),
  shipImo: z.number(),
  shipTerminal: z.string(),
  shipCradle: z.string(),
  startedAt: z.date(),
  finishedAt: z.date(),
  date: z.date(),
  requesterId: z.number(),
  arrivedAt: z.date().nullable(),
  onBoardAt: z.date().nullable(),
  outBoardAt: z.date().nullable(),
  internalStartedAt: z.date().nullable(),
  internalFinishedAt: z.date().nullable(),
  onMachineRoomStartedAt: z.date().nullable(),
  onMachineRoomFinishedAt: z.date().nullable(),
  externalStartedAt: z.date().nullable(),
  externalFinishedAt: z.date().nullable(),
  state: z.object({
    id: z.number(),
    name: z.string(),
  }),
  status: z.object({
    id: z.number(),
    name: z.string(),
  }),
  shipType: z.object({
    id: z.number(),
    name: z.string(),
  }),
  drivers: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  dogs: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      age: z.number(),
      breed: z.string(),
    })
  ),
  responsible: z.object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    isActive: z.boolean(),
    role: z.string(),
    avatar: z.string(),
    primaryPhoneNumber: z.string(),
    secondaryPhoneNumber: z.string(),
  }),
  images: z.array(ImageSchema),
  companion: CompanioSchema,
  report: ReportSchema,
  authorities: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export type InspectionSchema = z.infer<typeof InspectionSchema>;

export const InspectionFileSchema = z.object({
  id: z.number(),
  bucket: z.string(),
  inspectionId: z.number(),
  mimeType: z.string(),
  uploadedBy: z.number(),
  objectId: z.string(),
  tagId: z.number(),
  notInspectionedLocal: z.boolean(),
  notInspectionedReasonId: z.number(),
  tag: z.object({
    id: z.number(),
    tag: z.string(),
    description: z.string(),
  }),
  notInspectionedReason: z.number(),
});

export type InspectionFileSchema = z.infer<typeof InspectionFileSchema>;
