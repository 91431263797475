import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { backoffice, setAxiosAuthorizationHeader } from 'src/services';
import { authStore } from 'src/hooks/auth.store';
import { useSnackbar } from 'notistack';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { translate, onChangeLang } = useLocales();
  const login = authStore((s) => s.login);
  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('O e-mail deve ser um endereço de e-mail válido')
      .required('O e-mail é obrigatório'),
    password: Yup.string().required('Senha requerida'),
  });

  const { mutate, isLoading } = backoffice.auth.signIn.useMutation({
    onSuccess: (data, variables, context) => {
      const { user, token } = data.body;
      if (user.role != 'Driver') {
        const isRequester = ['Requester', 'Authority'].includes(user.role);
        setAxiosAuthorizationHeader(token);
        login(user, token);
        if (isRequester) {
          onChangeLang('en');
          navigate(PATH_DASHBOARD.requester.inspection);
        } else {
          navigate(PATH_DASHBOARD.inspection.root);
          onChangeLang('fr');
        }
      } else enqueueSnackbar('Usuário não encontrado!', { variant: 'error' });
    },
    onError(error, variables, context) {
      if (error.status === 401) enqueueSnackbar('Senha errada', { variant: 'error' });
      if (error.status === 403) enqueueSnackbar('Conta desactivada!', { variant: 'warning' });
      if (error.status === 404) enqueueSnackbar('Usuário não encontrado!', { variant: 'error' });
      else enqueueSnackbar('Erro', { variant: 'error' });
    },
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (body: FormValuesProps) => {
    try {
      mutate({ body });
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label={translate('signIn.email')} />

        <RHFTextField
          name="password"
          label={translate('signIn.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {translate('signIn.forgetPassword')}
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
        {translate('signIn.login')}
      </LoadingButton>
    </FormProvider>
  );
}
