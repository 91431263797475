import { z } from 'zod';
import { contract } from '../init-contract';
import { DogCreateSchema, DogSchema } from './dog.schema';
import { ERROR } from '../@types';

export const dogContract = contract.router({
  create: {
    summary: 'Show user details',
    method: 'POST',
    path: '/dog/create',
    responses: {
      200: contract.type<DogSchema>(),
      404: contract.type<ERROR>(),
    },
    body: DogCreateSchema,
  },
  list: {
    summary: 'List users',
    method: 'GET',
    path: '/dog',
    responses: {
      200: contract.type<DogSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  update: {
    summary: 'Show user details',
    method: 'PATCH',
    path: '/dog/update/:dogId',
    responses: {
      200: contract.type<DogSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      breed: z.string(),
      age: z.number(),
      state_id: z.number(),
    }),
    pathParams: z.object({
      dogId: z.string().uuid(),
    }),
  },
  show: {
    summary: 'Show user details',
    method: 'GET',
    path: '/dog/:dogId',
    responses: {
      200: contract.type<DogSchema>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      dogId: z.string().uuid(),
    }),
  },
  delete: {
    summary: 'Show user details',
    method: 'DELETE',
    path: '/dog/:dogId',
    responses: {
      200: contract.type<DogSchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      dogId: z.string().uuid(),
    }),
  },
});
