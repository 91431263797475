import { z } from 'zod';

export const UserSchema = z.object({
  id: z.number(),
  email: z.string(),
  name: z.string(),
  password: z.string(),
  cpf: z.string(),
  rg: z.string(),
  city: z.string(),
  birthDate: z.date(),
  isActive: z.boolean(),
  role: z.string(),
  avatar: z.string(),
  primaryPhoneNumber: z.string(),
  secondaryPhoneNumber: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
  gender: z.enum(['m', 'f']),
});

export type UserSchema = z.infer<typeof UserSchema>;
