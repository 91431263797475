import { z } from 'zod';

export const DogSchema = z.object({
  id: z.string(),
  name: z.string(),
  breed: z.string(),
  age: z.number(),
  state: z.object({
    id: z.number(),
    name: z.string(),
    state: z.string(),
  }),
});

export type DogSchema = z.infer<typeof DogSchema>;

export const DogCreateSchema = z.object({
  name: z.string(),
  breed: z.string(),
  age: z.number(),
  state_id: z.number(),
});

export type DogCreateSchema = z.infer<typeof DogCreateSchema>;
