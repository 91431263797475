import { contract } from './init-contract';
import { adminContract, adminAuthContract } from './admin';
import { userContract } from './user';
import { StateContract } from './state';
import { CityContract } from './city';
import { dogContract } from './dog';
import { inspectionContract } from './inspection';
import { shipContract } from './ship';
import { deckItemContract } from './deck';

export const backofficeContract = contract.router({
  admin: adminContract,
  auth: adminAuthContract,
  user: userContract,
  state: StateContract,
  city: CityContract,
  dog: dogContract,
  inspection: inspectionContract,
  ship: shipContract,
  deckItem: deckItemContract,
});
