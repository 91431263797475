// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  {
    subheader: 'gerenciamento',
    items: [
      {
        title: 'inspeção',
        path: PATH_DASHBOARD.inspection.root,
        icon: <Iconify icon="ph:detective-fill" />,
      },

      {
        title: 'Administradores',
        path: PATH_DASHBOARD.admin.root,
        icon: <Iconify icon="ri:admin-line" />,
      },
      {
        title: 'Agente contratante',
        path: PATH_DASHBOARD.requester.root,
        icon: <Iconify icon="ph:detective-fill" />,
      },
      {
        title: 'Autoridades',
        path: PATH_DASHBOARD.authority,
        icon: <Iconify icon="ic:sharp-local-police" />,
      },

      {
        title: 'Condutores',
        path: PATH_DASHBOARD.driver.root,
        icon: ICONS.user,
      },
      {
        title: 'Cães',
        path: PATH_DASHBOARD.dog.root,
        icon: <Iconify icon="f7:dog" />,
      },
      {
        title: 'Navio',
        path: PATH_DASHBOARD.ship.root,
        icon: <Iconify icon="fontisto:ship" />,
      },
      {
        title: 'Itens da deck',
        path: PATH_DASHBOARD.deck.root,
        icon: <Iconify icon="material-symbols:checklist" />,
      },
      {
        title: 'Estados',
        path: PATH_DASHBOARD.state.root,
        icon: <Iconify icon="clarity:administrator-solid" />,
      },
    ],
  },
];
export const adminConfig = [
  {
    subheader: 'gerenciamento',
    items: [
      {
        title: 'inspeção',
        path: PATH_DASHBOARD.inspection.root,
        icon: <Iconify icon="ph:detective-fill" />,
      },

      {
        title: 'Condutores',
        path: PATH_DASHBOARD.driver.root,
        icon: ICONS.user,
      },
      {
        title: 'Agente contratante',
        path: PATH_DASHBOARD.requester.root,
        icon: <Iconify icon="ph:detective-fill" />,
      },
      {
        title: 'Autoridades',
        path: PATH_DASHBOARD.authority,
        icon: <Iconify icon="ic:sharp-local-police" />,
      },
      {
        title: 'Cães',
        path: PATH_DASHBOARD.dog.root,
        icon: <Iconify icon="f7:dog" />,
      },
      {
        title: 'Navio',
        path: PATH_DASHBOARD.ship.root,
        icon: <Iconify icon="fontisto:ship" />,
      },
      {
        title: 'Itens da deck',
        path: PATH_DASHBOARD.deck.root,
        icon: <Iconify icon="material-symbols:checklist" />,
      },
      {
        title: 'Estados',
        path: PATH_DASHBOARD.state.root,
        icon: <Iconify icon="clarity:administrator-solid" />,
      },
    ],
  },
];

export const RequesternavConfig = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Inspection',
        path: PATH_DASHBOARD.requester.inspection,
        icon: <Iconify icon="ph:detective-fill" />,
      },
    ],
  },
];

export default navConfig;
