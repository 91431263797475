import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}>
      <img alt={'logo'} src="/assets/logo/logo.jpeg" style={{ width: 32, height: 32 }} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
