// hooks
import { authStore } from 'src/hooks/auth.store';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = authStore();

  return (
    <Avatar
      src={user?.avatar}
      alt={user?.name}
      color={user?.avatar ? 'default' : 'primary'}
      {...other}
    >
      {/* {createAvatar(user?.name).name} */}
    </Avatar>
  );
}
