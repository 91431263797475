import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      // children: [
      //   { element: <HomePage />, index: true },
      //   { path: 'about-us', element: <About /> },
      //   { path: 'contact-us', element: <Contact /> },
      //   { path: 'faqs', element: <Faqs /> },
      // ],
    },
    {
      path: 'auth/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'deactivate-account', element: <DeactivateAccount /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'inspection', element: <Inspection /> },
        { path: 'inspection/:inspectionId/files', element: <Files /> },
        { path: 'inspection/:inspectionId/status', element: <Status /> },
        { path: 'admin', element: <Admin /> },
        { path: 'driver', element: <Drivers /> },
        { path: 'deck', element: <Deck /> },
        { path: 'state', element: <State /> },
        { path: 'ship', element: <Ship /> },
        { path: 'dog', element: <Dog /> },
        { path: 'requester', element: <Requester /> },
        { path: 'authority', element: <Authority /> },
        { path: 'requester/inspection', element: <RequesterInspection /> },
        { path: 'user/account', element: <UserAccount /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const DeactivateAccount = Loadable(lazy(() => import('../pages/auth/deactivate-account')));

// DASHBOARD

// GENERAL
const Inspection = Loadable(lazy(() => import('../pages/dashboard/inspection')));
const Files = Loadable(lazy(() => import('../pages/dashboard/files')));
const Status = Loadable(lazy(() => import('../pages/dashboard/status')));
const Drivers = Loadable(lazy(() => import('../pages/dashboard/drivers')));
const Deck = Loadable(lazy(() => import('../pages/dashboard/deck')));
const State = Loadable(lazy(() => import('../pages/dashboard/state')));
const Ship = Loadable(lazy(() => import('../pages/dashboard/ship')));
const Dog = Loadable(lazy(() => import('../pages/dashboard/dog')));
const Admin = Loadable(lazy(() => import('../pages/dashboard/admins')));
const Requester = Loadable(lazy(() => import('../pages/dashboard/requester')));
const Authority = Loadable(lazy(() => import('../pages/dashboard/authority')));
const RequesterInspection = Loadable(lazy(() => import('../pages/dashboard/requester-inspection')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/account')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
