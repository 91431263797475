import { z } from 'zod';

export const AdminSchema = z.object({
  id: z.number(),
  email: z.string(),
  name: z.string(),
  password: z.string(),
  cpf: z.string(),
  rg: z.string(),
  city: z.string(),
  birthDate: z.date(),
  isActive: z.boolean(),
  role: z.string(),
  avatar: z.string(),
  gender: z.string(),
  primaryPhoneNumber: z.string(),
  secondaryPhoneNumber: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deletedAt: z.date(),
});

export type AdminSchema = z.infer<typeof AdminSchema>;
