import { backoffice, setAxiosAuthorizationHeader } from 'src/services';
import { AdminSchema } from 'src/services/contract';
import { create } from 'zustand';
type UseStore = {
  user: AdminSchema | null;
  token: string;
  isAuthenticated: boolean;
  isInitialized: boolean;
  login: (user: AdminSchema, token: string) => void;
  update: (user: Partial<AdminSchema>) => void;
  logout: VoidFunction;
  initialize: VoidFunction;
};
export const authStore = create<UseStore>((set, get) => ({
  user: null,
  token: '',
  isAuthenticated: false,
  isInitialized: false,
  login: (user: AdminSchema, token: string) => {
    localStorage.setItem('accessToken', token);
    set({ user, token, isAuthenticated: true });
  },
  update: (user: Partial<AdminSchema>) => {
    if (!get().user) return;
    set((state) => ({ user: { ...state.user!, ...user } }));
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    set((state) => ({ user: null, token: '' }));
  },

  initialize: async () => {
    const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
    if (accessToken) {
      setAxiosAuthorizationHeader(accessToken);
      backoffice.admin.profile
        .query()
        .then((response) => {
          set({
            isInitialized: true,
            isAuthenticated: true,
            user: response.body,
          });
        })
        .catch((error) => {
          set((state) => ({ isInitialized: true, isAuthenticated: false }));
        });
    } else {
      set((state) => ({ isInitialized: true, isAuthenticated: false }));
    }
  },
}));
