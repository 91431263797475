import { z } from 'zod';
import { contract } from '../init-contract';
import { StateSchema } from './schema';
import { ERROR } from '../@types';

export const StateContract = contract.router({
  list: {
    summary: 'List states',
    method: 'GET',
    path: '/state',
    responses: {
      200: contract.type<StateSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  create: {
    method: 'POST',
    summary: 'Create state',
    path: '/state',
    responses: {
      200: contract.type<StateSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      acronym: z.string(),
    }),
  },
  update: {
    method: 'PUT',
    summary: 'Update state',
    path: '/state/:stateId',
    responses: {
      200: contract.type<StateSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      acronym: z.string(),
    }),
    pathParams: z.object({
      stateId: z.string(),
    }),
  },
  delete: {
    method: 'DELETE',
    summary: 'Delete state',
    path: '/state/:stateId',
    responses: {
      200: contract.type<StateSchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      stateId: z.string(),
    }),
  },
});
