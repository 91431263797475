// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormLabel, Stack, TextField, TextFieldProps } from '@mui/material';
import { TextareaHTMLAttributes } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
};

type Props = IProps;

export default function RHFTextArea({ name, label }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} />
        <Stack>
          <FormLabel>{label}</FormLabel>
          <textarea {...field} placeholder={label} style={{ padding: 10, minHeight: 100 }} />
          {error?.message && <p style={{ color: 'red' }}>{error?.message}</p>}
        </Stack>
      )}
    />
  );
}
