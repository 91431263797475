import { z } from 'zod';
import { contract } from '../init-contract';
import { UserSchema } from './user.schema';
import { ERROR } from '../@types';
import { fileSchema } from '../@types/file';

export const userContract = contract.router({
  create: {
    summary: 'Create a new admin',
    method: 'POST',
    path: '/user/requester/create',
    responses: {
      201: UserSchema,
      422: contract.type<ERROR>(),
    },
    body: z.object({
      email: z.string().email(),
      name: z.string(),
      password: z.string(),
      passwordConfirmation: z.string(),
      gender: z.enum(['f', 'm']),
      primaryPhoneNumber: z.string(),
      secondaryPhoneNumber: z.string(),
    }),
  },
  list: {
    summary: 'List users',
    method: 'GET',
    path: '/user',
    responses: {
      200: contract.type<UserSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  status: {
    method: 'POST',
    summary: 'Update user account status',
    path: '/user/status',
    responses: {
      201: null,
    },
    body: z.object({
      userId: z.number(),
    }),
  },
  delete: {
    method: 'DELETE',
    summary: 'Update user account status',
    path: '/user/:userId',
    responses: {
      201: null,
    },
    body: null,
    pathParams: z.object({
      userId: z.number()
    }),
  },
  show: {
    summary: 'Show user details',
    method: 'GET',
    path: '/user/:userId',
    responses: {
      200: contract.type<UserSchema>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      userId: z.number()
    }),
  },
  update: {
    summary: 'Sign Up',
    method: 'PUT',
    path: '/user/admin/update-profile',
    responses: {
      200: contract.type<UserSchema>(),
    },
    body: z.object({
      name: z.string().email(),
      email: z.string().email(),
      gender: z.enum(['m', 'f']),
      primaryPhoneNumber: z.string(),
      secondaryPhoneNumber: z.string(),
      // profile_picture: fileSchema,
    }),
  },
  updateAvatar: {
    method: 'PATCH',
    path: '/user/updatePicture',
    contentType: 'multipart/form-data',
    responses: {
      200: contract.type<UserSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      profile_picture: fileSchema,
    }),
  },
});
