import { z } from 'zod';
import { contract } from '../init-contract';
import { ShipSchema } from './ship.schema';
import { ERROR } from '../@types';

export const shipContract = contract.router({
  create: {
    method: 'POST',
    path: '/shipType/create',
    responses: {
      200: contract.type<ShipSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
    }),
  },
  list: {
    method: 'GET',
    path: '/shipType',
    responses: {
      200: contract.type<ShipSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  update: {
    method: 'PATCH',
    path: '/shipType/update/:shipId',
    responses: {
      200: contract.type<ShipSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
    }),
    pathParams: z.object({
      shipId: z.string(),
    }),
  },
  show: {
    method: 'GET',
    path: 'shipType/:shipId',
    responses: {
      200: contract.type<ShipSchema>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      shipId: z.string(),
    }),
  },
  delete: {
    method: 'DELETE',
    path: '/shipType/:shipId',
    responses: {
      200: contract.type<ShipSchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      shipId: z.string(),
    }),
  },
});
