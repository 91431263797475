import { InspectionFileSchema } from 'src/services/contract';

export const isObject = (value: unknown): value is Record<string, unknown> =>
  typeof value === 'object' && value !== null;

export const isEmptyObject = (obj: unknown) => {
  if (!isObject(obj)) return true;

  return Object.keys(obj).length === 0;
};

export function sortByTag(files: InspectionFileSchema[]): InspectionFileSchema[] {
  const desiredOrder = [
    'photo_front_terminal',
    'ship_frontal_image',
    'internal_inspection_image',
    'external_inspection_image',
    'machine_room_image',
    'not_inspectioned_local_image',
    'crew_list_image',
    'imediate_report_image',
    'final_front_ship_image',
  ];

  return files.slice().sort((fileA, fileB) => {
    const tagA = fileA.tag.tag;
    const tagB = fileB.tag.tag;

    const indexA = desiredOrder.indexOf(tagA);
    const indexB = desiredOrder.indexOf(tagB);

    // If tags are found in the desired order, sort based on their position
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If a tag is not found in the desired order, put it at the end
    return indexA !== -1 ? -1 : 1;
  });
}
