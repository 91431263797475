import { z } from 'zod';
import { contract } from '../init-contract';
import { type AdminSchema } from './admin.schema';
import { ERROR } from '../@types';

export const adminAuthContract = contract.router({
  signIn: {
    summary: 'Sign In',
    method: 'POST',
    path: '/auth/admin/sign-in',
    responses: {
      200: contract.type<{ token: string; user: AdminSchema }>(),
      401: contract.type<ERROR>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      email: z.string().email(),
      password: z.string(),
    }),
  },

  signOut: {
    summary: 'Sign Out',
    method: 'POST',
    path: '/auth/admin/sign-out',
    responses: {
      204: null,
    },
    body: null,
  },
  signUp: {
    summary: 'Sign Up',
    method: 'POST',
    path: '/user/admin/create',
    responses: {
      201: contract.type<{ token: string; user: AdminSchema }>(),
      // 401: contract.type<ERROR>(),
      // 404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string().email(),
      email: z.string().email(),
      password: z.string().email(),
      passwordConfirmation: z.string().email(),
      gender: z.enum(['m', 'f']),
      role_id: z.number(),
      primaryPhoneNumber: z.string(),
      secondaryPhoneNumber: z.string(),
    }),
  },
  forgot: {
    method: 'POST',
    path: '/auth/forgot-password',
    responses: {
      201: null,
    },
    body: z.object({
      email: z.string().email(),
    }),
  },
  verify: {
    method: 'POST',
    path: '/auth/verify',
    responses: {
      201: null,
    },
    body: z.object({
      email: z.string().email(),
      password: z.string(),
      passwordConfirmation: z.string(),
      code: z.number(),
    }),
  },
  deactivate: {
    method: 'PUT',
    path: '/user/account/deactivate',
    responses: {
      201: null,
      404: contract.type<ERROR>(),
    },
    body: z.object({
      email: z.string().email(),
    }),
  },
});
