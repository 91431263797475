import { z } from 'zod';
import { contract } from '../init-contract';
import { CitySchema } from './schema';
import { ERROR } from '../@types';

export const CityContract = contract.router({
  list: {
    summary: 'List city',
    method: 'GET',
    path: '/city',
    responses: {
      200: contract.type<CitySchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  create: {
    method: 'POST',
    summary: 'Create city',
    path: '/city',
    responses: {
      200: contract.type<CitySchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      stateId: z.number(),
    }),
  },
  update: {
    method: 'PUT',
    summary: 'Update city',
    path: '/city/:cityId',
    responses: {
      200: contract.type<CitySchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
      stateId: z.number(),
    }),
    pathParams: z.object({
      cityId: z.string(),
    }),
  },
  delete: {
    method: 'DELETE',
    summary: 'Delete city',
    path: '/city/:cityId',
    responses: {
      200: contract.type<CitySchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      cityId: z.string(),
    }),
  },
});
