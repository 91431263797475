import type { AxiosError, AxiosResponse, Method } from 'axios';
import axios, { isAxiosError } from 'axios';
import { initQueryClient } from '@ts-rest/react-query';
import { QueryClient } from '@tanstack/react-query';

import { apiUrl } from './constants';
import { backofficeContract } from './contract';
import { isEmptyObject } from '../utils/assertion';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const axiosInstance = axios.create({
  baseURL: apiUrl,
  // withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
});

export function setAxiosAuthorizationHeader(token?: string) {
  if (!token) {
    delete axiosInstance.defaults.headers.common.Authorization;
    return;
  }

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export const backoffice = initQueryClient(backofficeContract, {
  baseUrl: apiUrl,
  baseHeaders: {},
  api: async ({ path, method, headers, body }) => {
    try {
      const result = await axiosInstance.request({
        method: method as Method,
        url: path,
        data: body,
        // headers,
        headers: isEmptyObject(headers)
          ? {
              'Content-type': 'multipart/form-data',
              Accept: 'application/json',
            }
          : { 'Content-type': 'application/json', Accept: 'application/json' },
      });
      return { status: result.status, body: result.data, headers: result.headers as any };
    } catch (e: Error | AxiosError | any) {
      if (isAxiosError(e)) {
        const error = e as AxiosError;
        const response = error.response as AxiosResponse;
        return { status: response.status, body: response.data, headers: response.headers };
      }
      throw e;
    }
  },
});
