import { z } from 'zod';
import { contract } from '../init-contract';
import { DeckSchema } from './deck.schema';
import { ERROR } from '../@types';

export const deckItemContract = contract.router({
  create: {
    method: 'POST',
    path: '/deckItem',
    responses: {
      200: contract.type<DeckSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
    }),
  },
  list: {
    method: 'GET',
    path: '/deckItem',
    responses: {
      200: contract.type<DeckSchema[]>(),
      404: contract.type<ERROR>(),
    },
  },

  update: {
    method: 'PUT',
    path: '/deckItem/:deckItemId',
    responses: {
      200: contract.type<DeckSchema>(),
      404: contract.type<ERROR>(),
    },
    body: z.object({
      name: z.string(),
    }),
    pathParams: z.object({
      deckItemId: z.string(),
    }),
  },
  show: {
    method: 'GET',
    path: '/deckItem/:deckItemId',
    responses: {
      200: contract.type<DeckSchema>(),
      404: contract.type<ERROR>(),
    },
    pathParams: z.object({
      deckItemId: z.string(),
    }),
  },
  delete: {
    method: 'DELETE',
    path: '/deckItem/:deckItemId',
    responses: {
      200: contract.type<DeckSchema>(),
      404: contract.type<ERROR>(),
    },
    body: null,
    pathParams: z.object({
      deckItemId: z.string(),
    }),
  },
});
