const en = {
  logOut: 'Logout',
  no_inspection: 'No inspections yet.',
  signIn: {
    welcome: 'Hello, welcome back',
    loginDBK9: 'Log in to DBK9',
    inserData: 'Enter your details below.',
    email: 'Email address',
    password: 'Password',
    forgetPassword: 'Forgot your password?',
    login: 'Login',
  },
  inspection: {
    title: 'Inspections',
    heading: 'List of inspections',
    list: 'List',
    newInspection: 'New inspection',
    started: 'Started',
    pending: 'Pending',
    finished: 'Finished',
  },
  status: {
    title: 'Inspection',
    heading: 'Inspection info',
    inspection: 'Inspection',
    hour: 'Time',
    atTerminal: 'ARRIVED AT TERMINAL',
    onBoard: 'CLIMB ON BOARD',
    outBoard: 'CLIMB OUT BOARD',
    internalInspectionStartedAt: 'START INTERNAL INSPECTION',
    internalInspectionFinishedAt: 'FINAL INTERNAL INSPECTION',
    externalInspectionStartedAt: 'START EXTERNAL INSPECTION',
    externalInspectionFinishedAt: 'FINAL EXTERNAL INSPECTION',
    onMachineRoomStartedAt: 'START INSPECTION OF ENGINE ROOM',
    onMachineRoomFinishedAt: 'FINAL INSPECTION ENGINE ROOM',
    inspectionEnd: 'END OF INSPECTION',
    status: 'Status',
    info1: 'OUR TEAM IS CARRYING OUT INSPECTION, WE WILL HAVE MORE INFORMATION SOON',
    info2:
      'IF THE TEAM HAS ACCESS TO THE INTERNET AT THE END OF THE INSPECTION PERIOD YOU WILL HAVE ACCESS TO THE MEDIA AT THE INSPECTED LOCATION',
    midia: 'Midia',
    finalReport: 'Final report',
  },
  midia: {
    title: 'Inspections',
    heading: 'Inspections files',
    list: 'List',
    files: 'Files',
    inspection: 'Inspection',
    report: 'Report',
    noFile: 'No files have been uploaded yet',
    ship_frontal_image: 'Front photo of the ship',
    ship_back_image: 'Rear photo of the ship',
    internal_inspection_image: 'Video of internal inspection',
    external_inspection_image: 'Video of external inspection',
    machine_room_image: 'Video of the engine room',
    not_inspectioned_local_image: 'Video of the uninspected location',
    crew_list_image: 'Crew list photo',
    imediate_report_image: 'Photo of the immediate report',
    final_front_ship_image: 'Final photo in front of the ship',
    drog_found_image: 'Video of narcotics found',
    midia_image_deck_tag: 'Video of deck number {{deckNumber}}',
    photo_front_terminal:"Arrival at port terminal"
  },
};

export default en;
