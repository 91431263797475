import { z } from 'zod';

import { contract } from '../init-contract';
import { AdminSchema } from './admin.schema';
import { ERROR } from '../@types';

export const adminContract = contract.router({
  profile: {
    summary: 'Get admin account details',
    method: 'GET',
    path: '/user/account/profile',
    responses: {
      200: AdminSchema,
      404: contract.type<ERROR>(),
    },
  },

  create: {
    summary: 'Create a new admin',
    method: 'POST',
    path: '/user/admin/create',
    responses: {
      201: AdminSchema,
      422: contract.type<ERROR>(),
    },
    body: z.object({
      email: z.string().email(),
      name: z.string(),
      password: z.string(),
      passwordConfirmation: z.string(),
      gender: z.enum(['f', 'm']),
      primaryPhoneNumber: z.string(),
      secondaryPhoneNumber: z.string(),
      role_id: z.number(),
    }),
  },

  changePassword: {
    summary: 'Change admin password',
    method: 'PUT',
    path: '/user/account/change-password',
    responses: {
      201: AdminSchema,
      401: contract.type<ERROR>(), //Nao autorizado
      400: contract.type<Omit<ERROR, 'error'>>(), //senhas nao sao iguais
    },
    body: z.object({
      oldpassword: z.string(),
      password: z.string(),
      passwordConfirmation: z.string(),
    }),
  },

  avatar: {
    method: 'PATCH',
    path: '/user/updatePicture',
    contentType: 'multipart/form-data',
    responses: {
      200: contract.type<AdminSchema>(),
    },
    body: z.object({
      profile_picture: z.any(),
    }),
  },
});
