import { z } from 'zod';
import { StateSchema } from '../state';

export const CitySchema = z.object({
  id: z.string(),
  name: z.string(),
  state: StateSchema,
});

export type CitySchema = z.infer<typeof CitySchema>;
