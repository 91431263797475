import { z } from 'zod';

import { contract } from './init-contract';

export const uploadContract = contract.router({
  download: {
    summary: 'Get a file (image, pdf, etc.)',
    method: 'GET',
    path: '/backoffice/uploads/:folder/:filename',
    query: null,
    responses: {
      200: contract.body<File>(),
      404: contract.body<null>(),
    },
    pathParams: z.object({
      folder: z.string(),
      filename: z.string(),
    }),
  },
});
